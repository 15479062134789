.home-container {
    position: relative;
  }
  
  .background-container {
    position: relative;
    height: 80vh;
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    color: white; /* Set text color to white */
  }
  
  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .call-us-button {
    margin-top: 5px;
  }
  
  /* Home.css */

@media (max-width: 768px) {
  /* Adjust font size for smaller screens */
  .background-container h1 {
    font-size: 24px;
  }
  
  .background-container p {
    font-size: 16px;
  }
}
