/* Set the background of the products section to be a bit transparent */
.products-section {
  background-color: rgba(255, 255, 255, 0.178); /* White background with 80% opacity */
}

/* Apply border-radius to the images in the carousel */
.image-rounded {
  border-radius: 10px; /* Adjust the value as desired for rounded corners */
  box-shadow: 50px;
}
