.services-container {
    border-radius: 10px;
    background-color: #f5f5f5; /* Gray background color */
    padding: 20px;
    margin-top: 20px; /* Adjust margin as needed */
  }
  
  .services-title {
    color: #333; 
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* .services-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  } */
  
  .card {
    background-color: #ffffff; 
    border: 1px solid #ccc; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    /* width: calc(50% - 20px);  */
    padding: 20px;
    margin: 10px; 
    text-align: center;
  }
  
  .card-title {
    color: #333; 
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  @media (min-width: 992px) {
    .card {
      width: calc(25% - 20px); 
    }
  }
  