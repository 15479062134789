/* Apply background image and style to the form container */
.store-materials-container {
    background-image: url('../Images/soteback.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: 'rgba(0, 0, 0, 0.8)';
    /* backgroundColor: 'rgba(0, 0, 0, 0.5)'; */
    height: 100vh;
}

/* Styling for the form card */
.custom-card {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.8);
}

/* Adjusting the input and label in a row */
.row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
