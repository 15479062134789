.clr {
    background-color: rgb(49, 77, 77);
    color: #fff;
    text-align: center;
    padding-top: 5px;
    border-radius: 30px;
}


.responsive-map {
    overflow: hidden;
    height: 0;
    padding-bottom: 30%;
    position: relative;
}

.responsive-map iframe {
    border: 0;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: absolute;
}