
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .about-container {
    box-shadow: whitesmoke;
    border-radius: 10px;
    background-color: #f5f5f5;
    /* background-color: rgb(49, 77, 77); */
    padding: 5px;
    margin-top: 5px; /* Adjust margin as needed */
  }
  .for-color {
    background-color: rgb(49, 77, 77);
    border-radius: 10px;
    padding: 20px;
  }
  /* .row{
    background-color: #f5f5f5;
  } */

  .image{
    border-radius: 10px;
    box-shadow: 20px;
  }

  .overflow-hidden {
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  .image-wrapper {
    width: 600px; /* Set a fixed width for each image */
    margin-right: 10px; /* Add some space between images */
  }
  
  .image {
    width: 100%; /* Ensure the image fills its container */
    height: auto; /* Allow images to resize based on width */
  }
  