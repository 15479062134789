.copyright-container {
    background-color: rgb(49, 77, 77);
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .copyright-container p {
    margin: 0;
  }
  